import React from "react"
import { graphql } from "gatsby"

import Layout from "@/components/Layout/PageRoot"
import SEO from "@/components/Layout/SEO"
import Footer from "@/components/Common/Footer"
import PageContent from "@/components/Layout/PageContent"
import Section from "@/components/Home/Section"
import styled from "styled-components"
import Logo from "@/components/Home/Icons/Logo"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 32px 0 64px 0;
  text-align: center;
  font-family: "Noto Sans JP";
`

const NotFoundPage = ({ data }: Props) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout>
      <SEO title="404: Not Found" />
      <PageContent>
        <Section>
          <Wrapper>
            <Logo style={{ width: "600px", maxWidth: "100%" }} />
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn't exist...</p>
          </Wrapper>
        </Section>
      </PageContent>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
